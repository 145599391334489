import classnames from 'classnames';

interface LayoutPaddingXProps {
  children: React.ReactNode;
  className?: string;
}

export default function LayoutPaddingX({
  children,
  className,
}: LayoutPaddingXProps) {
  return (
    <div className={classnames('px-4 sm:px-14', className)}>{children}</div>
  );
}
